<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'">

    <div class="mt-5">

      <Breadcrumbs :title="`Add New Questions`" :items="items"/>
      <Snackbar ref="snackbar"/>

      <div class="mb-8">
        <ValidationObserver ref="observer">
          <v-form>
            <div>
              <v-expansion-panels flat v-model="panel">
                <v-expansion-panel expand v-model="panel" v-for="(item,i) in questions" :key="i" class="mb-3 rounded-lg" style="border: 1px solid #ddd">
                  <v-expansion-panel-header>
                    <span class="font-weight-bold text-h6"> Number {{ i + 1 }} </span>
                    <v-spacer></v-spacer>
                    <div class="text-right">
                      <v-btn
                        icon
                        small
                        @click.stop="confirmDelete(i)">
                        <v-icon color="red">mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </div>
                  </v-expansion-panel-header>
                  <v-divider></v-divider>
                  <v-expansion-panel-content>
                    <div class="mt-7">
                      <ValidationProvider :name="item.instruction" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          v-model="item.instruction"
                          :error-messages="errors"
                          label="Instructions"
                          autocomplete="off"
                          color="#F05326">
                        </v-text-field>
                      </ValidationProvider>

                      <!-- AUDIO QUESTION -->
                      <div>Question</div>
                      <v-card flat outlined class="mb-4">
                        <v-card-text>
                          <div class="d-flex">
                            <ValidationProvider :name="item.question" rules="required" v-slot="{ errors }">
                              <v-text-field
                                solo
                                flat
                                dense
                                class="px-5 mt-4"
                                style="width:400px"
                                v-model="item.question"
                                :error-messages="errors"
                                placeholder="Tuliskan pertanyaan disini"
                                autocomplete="off"
                                color="#F05326">
                              </v-text-field>
                            </ValidationProvider>
                          </div>
                          <v-spacer></v-spacer>
                          <v-row no-gutters style="max-width: 100%;justify-content: flex-end">
                            <audio-attachment
                              :src="item.fileQuestion.media_data"
                              @update="
                                $set(item.fileQuestion, 'media_data', {
                                  ...item.fileQuestion.media_data,
                                  ...$event,
                                })
                              "
                            />
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <!-- AUDIO QUESTION END -->

                      <ValidationProvider :name="item.correct_answer" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          v-model="item.correct_answer"
                          :error-messages="errors"
                          label="Correct Answer"
                          autocomplete="off"
                          color="#F05326">
                        </v-text-field>
                      </ValidationProvider>
                      <ValidationProvider :name="item.correct_explan" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          v-model="item.correct_explan"
                          :error-messages="errors"
                          label="Correct Answer Explanation"
                          autocomplete="off"
                          color="#F05326">
                        </v-text-field>
                      </ValidationProvider>
                      
                      <!-- AUDIO INCORRECT ANSWER -->
                      <div>Incorrect Explanation Answer</div>
                      <v-card flat outlined class="mb-4">
                        <v-card-text>
                          <ValidationProvider :name="item.incorrect_explan" rules="required" v-slot="{ errors }">
                            <v-text-field
                              solo
                              flat
                              dense
                              rows="2"
                              class="px-5 mt-4"
                              v-model="item.incorrect_explan"
                              :error-messages="errors"
                              placeholder="Tuliskan Jawaban disini"
                              autocomplete="off"
                              color="#F05326">
                            </v-text-field>
                          </ValidationProvider>
                          <v-row no-gutters style="max-width: 100%;justify-content: flex-end">
                            <audio-attachment
                              :src="item.fileAnswer.media_data"
                              @update="
                                $set(item.fileAnswer, 'media_data', {
                                  ...item.fileAnswer.media_data,
                                  ...$event,
                                })
                              "
                            />
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <!-- AUDIO INCORRECT ANSWER END -->

                      <ValidationProvider :name="`${item.point}`" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          v-model="item.point"
                          :error-messages="errors"
                          type="number"
                          label="Point"
                          autocomplete="off"
                          color="#F05326">
                        </v-text-field>
                      </ValidationProvider>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div class="mt-7">
              <v-btn 
                outlined
                dense
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run"
                class="text-capitalize"
                color="#F05326" 
                @click="addQuestion()">
                <v-icon left>mdi-plus</v-icon>
                Add Question
              </v-btn>
              <v-btn
                dense
                depressed
                class="text-capitalize white--text float-right"
                @click="setData"
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run"
                color="#F05326">
                Save
              </v-btn>
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run"
                class="text-capitalize black--text mr-5 float-right"
                @click="$router.push(`/questions/${$route.params.id_course}/${$route.params.id_topic}/${$route.params.id_activity}/${$route.params.activity_type}`)"
                color="#F5F5F5">
                Cancel
              </v-btn>
            </div>
          </v-form>
        </ValidationObserver>
      </div>

    </div>
  </div>
</template>

<script>
import { get, post, put , destroy, upload } from '@/service/Axios'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
import AudioAttachment from "@/components/media/layout/AudioAttachment.vue";
export default {
  data() {
    return { 
      /*
       * ANSWER short_answer
       */
      questions: [
        // {
        //   id: 1, 
        //   name: "Nomor 1",
        //   instruction: "",
        //   question: "",
        //   correct_answer: "",
        //   correct_explan: "",
        //   incorrect_explan: "",
        //   point: "",
        //   fileQuestion: {
        //     media_data:{}
        //   },
        //   fileAnswer: {
        //     media_data:{}
        //   },
        // }
      ],
      /*
       * ANSWER END 
       */
      panel: 0,
      items: [
        {
          text: 'Merekam Suara',
          disabled: false,
          href: `/questions/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.activity_type}`,
        },
        {
          text: 'Add New Questions',
          disabled: true,
          href: '/questions/create',
        }
      ],
      process:{
        run: false,
      },
    }   
  },
  watch: {},
  computed:{},
  components: { Breadcrumbs, Snackbar, AudioAttachment },
  mounted(){
    if(this.$route.params.id_question){
      this.fetch()
    }else {
      this.questions.push({
        id: "", 
        name: `Nomor ${this.questions.length + 1}`,
        instruction: "",
        question: "",
        correct_answer: "",
        correct_explan: "",
        incorrect_explan: "",
        point: 0,
        fileQuestion: {
          media_data:{}
        },
        fileAnswer: {
          media_data:{}
        },
      });
    }
  },
  created(){},
  methods:{
    async fetch(){
      this.$store.state.process.run = true
      await get(`bank/v1/question/detail/${this.$route.params.id_question}`)
      .then((response) => {
        let res = response.data
        // console.log(res);
        if(res.status == 200){
          this.$store.state.process.run = false
          let fileQuestion = {
            media_data: {
              with_media: false,
              _id: "",
            }
          }
          let fileAnswer = {
            media_data: {
              with_media: false,
              _id: "",
            }
          }
          let question = {
            id: res.data.id,
            instruction : res.data.instruction.text,
            question : res.data.question.text,
            fileQuestion: {
              media_data: {
                with_media: true,
                _id: res.data.question.media.id,
                media: res.data.question.media
              }
            },
            correct_answer : res.data.correct_answer[0],
            correct_explan : res.data.explanation.true.text,
            incorrect_explan : res.data.explanation.false.text,
            fileAnswer: {
              media_data: {
                with_media: true,
                _id: res.data.explanation.false.media.id,
                media: res.data.explanation.false.media
              }
            },
            point : res.data.point,
          }
          // console.log(question)
          this.questions.push(question)
        }else {
          this.$store.state.process.run = false
        }
      })
    },
    /*
     * METHOD QUESTION
     */
    addQuestion() {
      this.questions.push({
        id: "", 
        name: `Nomor ${this.questions.length + 1}`,
        instruction: "",
        question: "",
        correct_answer: "",
        correct_explan: "",
        incorrect_explan: "",
        point: 0,
        fileQuestion: {
          media_data:{}
        },
        fileAnswer: {
          media_data:{}
        },
      });
    },
    confirmDelete(idx){
      this.questions.splice(idx,1)
    },
    /*
    * END METHOD QUESTION 
    */
    /**
     * SAVE DATA
     */
    async setData(){
      const isValid = await this.$refs.observer.validate()
      if (this.questions.length < 1) {
        this.$store.state.process.run = false
        this.$refs.snackbar.open("error", `Soal tidak ditemukan`);
      }else{
        if (isValid) {
          this.$store.state.process.run = true
          let data = {}
          this.questions.forEach((item,i) => {
            this.$store.state.process.run = true
            if (Object.keys(item.fileQuestion.media_data).length == 0) {
              this.$store.state.process.run = false
              this.$refs.snackbar.open("error", `Media Question masih kosong`);
            }else{
              data.instruction_text = item.instruction;
              data.instruction_media = "";
              data.type = "short_answer";
              data.question_text = item.question;
              data.question_media = item.fileQuestion.media_data._id;
              data.point = parseInt(item.point);
              data.correct_answer = [item.correct_answer];
              data.status = "publish"
              data.explanation = {
                true_text: item.correct_explan,
                true_media: "",
                false_text: item.incorrect_explan,
                false_media: item.fileAnswer.media_data._id
              }
              if (item.id !== "") {
                this.save(`update`, `bank/v1/question/${this.$route.params.id_question}`, `api/v1/question/update/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.id_question}` , data)
              }else {
                this.save(`create`, `bank/v1/question/`, `api/v1/question/add/${this.$route.params.id_topic}/${this.$route.params.id_activity}`, data)
              }
            }
          })
        }else{
          this.$store.state.process.run = false
          this.$refs.snackbar.open("error", `Form Belum Valid`);
        }
      }
    },
    async save(params, url_question, url_activity, data_send){
      this.$store.state.process.run = true
      if(params == `update`){
        await put(url_question,{
          data: data_send
        })
        .then((response) => {
          let res = response.data
          if (res.status == 200) {
            put(url_activity,{
              data: {
                id: res.data.id,
                is_public: false,
                is_paid: true
              }
            })
            .then(response => {
              let res = response.data
              if (res.status == 200) {
                this.$refs.snackbar.open("#4CAF50", `New Question Updated Succesfully`);
                setTimeout(() => {
                  this.$store.state.process.run = false
                  this.$router.push(`/questions/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.activity_type}`)
                }, 1000);
              }else{
                this.$store.state.process.run = false
                this.$refs.snackbar.open("error", `New Question Updated Failed`);
              }
            })
          }else{
            this.$store.state.process.run = false
            this.$refs.snackbar.open("error", `New Question Updated Failed`);
          }
        })
      }else {
        await post(url_question,{
          data: data_send
        })
        .then((response) => {
          let res = response.data
          if (res.status == 200) {
            post(url_activity,{
              data: {
                id: res.data.id,
                is_public: false,
                is_paid: true
              }
            })
            .then(response => {
              let res = response.data
              if (res.status == 200) {
                this.$refs.snackbar.open("#4CAF50", `New Question Added Succesfully`);
                setTimeout(() => {
                  this.$store.state.process.run = false
                  this.$router.push(`/questions/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.activity_type}`)
                }, 1000);
              }else{
                this.$store.state.process.run = false
                this.$refs.snackbar.open("error", `New Question Added Failed`);
              }
            })
          }else{
            this.$store.state.process.run = false
            this.$refs.snackbar.open("error", `New Question Added Failed`);
          }
        })
      }
    },
    /**
     * SAVE DATA END
     */
  }
}
</script>

<style>
/* .v-input__append-inner {
  width: 500px;
} */
</style>